
import { defineComponent, onMounted, onBeforeMount } from "vue";
import ToolbarFilter from "@/components/toolbar/ToolbarFilter.vue";
import ToolbarActions from "@/components/toolbar/ToolbarActions.vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import useComponentv2 from '@/composables/v2/useComponent';
import FormTypes from '@/components/forms/v2/Types.vue';
import Upload from '@/components/forms/v2/Upload.vue';
import Icon from '@/components/icons/Icon.vue';

export default defineComponent({
  name: '',
  components: {
    ToolbarFilter,
    ToolbarActions,
    FormTypes,
    Icon,
  },
  emits: ["reload"],
  props: {
    currentId: String
  },
  setup (props, { emit }) {
    const { initComponent, componentData, componentRegisterId, componentLoading, updateComponent, submitButtonComponent, submitLoadingComponent, saveComponent, componentStatus, reloadComponent, test, renderModal, renderAmount, renderId, reloadValidation } = useComponentv2();

    initComponent({
      componentName: "ShipmentsShipmentAttachmentsUpload", 
      displayMethod: "new", /* new or existing */
      componentType: "upload", /* form or table */
      dispatchActions: { init: "FILE", save: "FILE_CREATE", params: { init: true, lines: [ { key: "shipment_id", value: props.currentId} ] } },
      componentReload: true,
      componentRedirect: { init: false, data: "", path: ""},
      currentData: "currentProductSpecification",
      componentFilter: { init: false, get: "currentSalesFilter" },
      componentToolbar: { init: true, filter: false, actions: { init: true, title: "Upload", menu: [ ] } },
      currentId: props.currentId,
    });

    onMounted(() => {
      MenuComponent.reinitialization();
    })

    return {
      componentData,
      componentRegisterId,
      componentLoading,
      componentStatus,
      updateComponent,
      submitButtonComponent,
      submitLoadingComponent,
      saveComponent,
      reloadComponent,
      test,
      renderModal,
      renderId,
      renderAmount,
      reloadValidation
    }
  }
});
