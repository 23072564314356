import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.componentList, (component) => {
    return (_openBlock(), _createElementBlock("div", {
      key: component.name
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(component.name), {
        "current-id": _ctx.currentId,
        key: _ctx.reload == component.name,
        onReload: ($event: any) => (_ctx.listenTo(component.name))
      }, null, 40, ["current-id", "onReload"]))
    ]))
  }), 128))
}