
import { ref, defineComponent, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useContentStore } from "@/stores/content";
import ShipmentsShipmentAttachmentsUpload from '@/components/ShipmentsShipmentAttachmentsUpload.vue';
import ShipmentsShipmentAttachmentsFiles from '@/components/ShipmentsShipmentAttachmentsFiles.vue';
import useInit from '@/composables/v2/useInit';

export default defineComponent({
  components: {
    ShipmentsShipmentAttachmentsUpload,
    ShipmentsShipmentAttachmentsFiles,
  },
  setup() {
    const route = useRoute();
    const { initTemplate, listenTo, componentList, reload } = useInit()

    initTemplate([
      { name: 'ShipmentsShipmentAttachmentsUpload', settings: { listenTo: { type: 'specific', components: [''] } } },
      { name: 'ShipmentsShipmentAttachmentsFiles', settings: { listenTo: { type: 'specific', components: ['ShipmentsShipmentAttachmentsUpload'] } } },
    ])

    const currentId = computed(() => {
      return route.params.uid
    });

    return {
      currentId,
      reload,
      componentList,
      listenTo
    }
  }
});
